var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "license-container" }, [
    _c("section", { staticClass: "license-title" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("message.license_title")))]),
      _c("p", {
        domProps: { innerHTML: _vm._s(_vm.$t("message.license_description")) },
      }),
    ]),
    _c(
      "section",
      { staticClass: "license-contents" },
      _vm._l(_vm.licenseData, function (data, index) {
        return _c("article", { key: index }, [
          _c("h2", [_vm._v(_vm._s(data.library))]),
          _c("div", { staticClass: "ml-20" }, [
            _c("a", { attrs: { href: data.url } }, [_vm._v(_vm._s(data.url))]),
            _c("p", [_vm._v(_vm._s(data.license))]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }