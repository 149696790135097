<template>
  <div class="license-container">
    <section class="license-title">
      <h1>{{ $t('message.license_title') }}</h1>
      <p v-html="$t('message.license_description')" />
    </section>
    <section class="license-contents">
      <article v-for="(data, index) in licenseData" :key="index">
        <h2>{{ data.library }}</h2>
        <div class="ml-20">
          <a :href="data.url">{{ data.url }}</a>
          <p>{{ data.license }}</p>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import OpenSourceLicense from '../../asset/data/OpenSourceLicense.json';

export default {
  name: 'OpenSourceLicense',
  props: [],
  data() {
    return {
      licenseData: [],
    };
  },
  computed: {},
  created() {
    this.getLicenseData();
  },
  mounted() {},
  watch: {},
  methods: {
    getLicenseData() {
      switch (this.$route.path) {
        case '/openSourceLicense/iOS':
          this.licenseData = OpenSourceLicense.iOS;
          break;
        case '/openSourceLicense/android':
          this.licenseData = OpenSourceLicense.android;
          break;
        case '/openSourceLicense/web':
          this.licenseData = OpenSourceLicense.web;
          break;
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import 'OpenSourceLicense';
</style>
